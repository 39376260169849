import {
    GET_PROFILE_DATA,
    GET_REPORTS,
    GET_REPORT
} from "../actions/types";

const initialState = {
    profile: null,
    reports: [],
    report: {},
    user: {}
}


function licenseeReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_PROFILE_DATA:
            return {
                ...state,
                profile: {
                    ...payload
                },
                loading: false,
            };
        case GET_REPORTS:
            const iter = payload?.items ? payload.items : payload;
            return {
                ...state,
                reports: [...iter],
                loading: false
            };
        case GET_REPORT:
            return {
                ...state,
                report: payload,
                loading: false
            };
        default:
            return state;
    }
}

export default licenseeReducer;
