
import api from "../utils/api";
import { isGuid } from "../utils/string";
import { GET_EVENTS, SET_EVENT, DELETE_EVENT, LOGOUT, SET_LOADED, SET_LOADING } from "./types";

export const client = (uuid, dateFrom, dateTo, eventUuid = null) => async (dispatch) => {
    try {
        
        const res = await api.get(`/clients/${uuid}/events/df/${dateFrom}/dt/${dateTo}${isGuid(eventUuid) ? `/${eventUuid}` : ""}`);

        if (res?.data) {
            dispatch({
                type: GET_EVENTS,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const lead = (uuid, dateFrom, dateTo, eventUuid = null) => async (dispatch) => {
    try {
        
        const res = await api.get(`/clients/${uuid}/events/df/${dateFrom}/dt/${dateTo}${isGuid(eventUuid) ? `/${eventUuid}` : ""}`);

        if (res?.data) {
            dispatch({
                type: GET_EVENTS,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const assigned = (dateFrom, dateTo, assignedToUuid, eventUuid = null) => async (dispatch) => {
    try {
        // /df/:dateFrom/dt/:dateTo/a/:assignedToUuid/e/:eventUuid
        const res = await api.get(`/events/df/${dateFrom}/dt/${dateTo}/a/${assignedToUuid}/e/${isGuid(eventUuid) ? eventUuid : "x"}`);

        if (res?.data) {
            dispatch({
                type: GET_EVENTS,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = (dateFrom, dateTo, assignedToUuid, eventUuid) => async (dispatch) => {
    try {
        // /df/:dateFrom/dt/:dateTo/a/:assignedToUuid/e/:eventUuid
        const res = await api.get(`/events/df/${dateFrom}/dt/${dateTo}/a/${assignedToUuid}/e/${eventUuid}`);

        if (res?.data) {
            dispatch({
                type: GET_EVENTS,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}


export const save = ({ path, uuid, activityUuid, subjectUuid, locationUuid, phoneUuid, caption, assignedToUuid, location, startDate, endDate, dueDate, completeDate, shares }) => async (dispatch) => {
    try {
        
        const res = await api.post(`/${path}/${subjectUuid}/events`, {uuid, activityUuid, subjectUuid, locationUuid, phoneUuid, caption, assignedToUuid, location, startDate, endDate, dueDate, completeDate, shares });

        if (res?.data) {
            dispatch({
                type: SET_EVENT,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const destroy = ({ path, uuid, subjectUuid }) => async (dispatch) => {
    try {
        await api.delete(`/${path}/${subjectUuid}/events/${uuid}`);
        dispatch({
            type: DELETE_EVENT,
            payload: { uuid: subjectUuid }
        });
        return { success: true }
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}