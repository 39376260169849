function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

function deepEqual(object1, object2) {
  if ((object1 && !object2) || (!object1 && object2)) return false;
  if (!object1 && !object2) return true;
  if (JSON.stringify(object1) === JSON.stringify(object2)) return true;

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function deepSet(object1, path, value) {
  const keys = path.split('.');
    let current = object1;

    // Traverse the path and create objects if necessary
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!current[key]) current[key] = {}; // Create the nested object if it doesn't exist
        current = current[key];
    }

    // Set the value at the deepest level
    current[keys[keys.length - 1]] = value;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

export { shallowEqual, deepEqual, deepSet, isObject };
