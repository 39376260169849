import React from "react";
import { Col, Form } from 'react-bootstrap';

import { deepSet, deepEqual } from "../../../utils/object";

class DateRange extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
    }

    get propsToState() {
        /*
        const sample = {
            fields: [
                {
                    type: "boolean",
                    //       index   meaning
                    //       0       selected
                    //       1       whenTrue
                    //       2       whenFalse
                    default: [1,1,0],
                    storeTo: "hide.name",
                    caption: "HIDE NAMES?"
                }
            ]
        }
        */

        let __working = {
            ...this.props
        }

        const __result = {
            caption: __working.caption || "Option?",
            default: __working.default?.length > 0 ? __working.default[0] || 1 : 1,
            storeTo: __working.storeTo || "option",
            options: {
                true: __working.default?.length > 1 ? __working.default[1] || 1 : 1,
                false: __working.default?.length > 2 ? __working.default[2] || 0 : 0,
            }
        }
        __result.current = __result.default === __result.options.true ? 1 : 0;
        __result.value = __result.default === __result.options.true ? __result.options.true : __result.options.false;

        if (this.props.onChange) this.props.onChange({ storeTo: __result.storeTo,  value: __result.value });
        return __result;
        
    }


    componentDidUpdate = (old) => {
        if (!deepEqual(this.props, old)) {
            this.setState(this.propsToState)
        }
    }

    
    onCheckChange = (e) => {
        const __current = this.state.current === 1 ? 0 : 1;
        const __result = this.state.result;
        const __value = __current === 1 ? this.state.options.true : this.state.options.false;
        this.setState({
            ...this.state,
            current: __current,
            value: __value
        }, this.onChange);
    }

    onChange = (e) => {
        if (this.props.onChange) this.props.onChange({ storeTo: this.state.storeTo, value: this.state.value });
    }

    render() {
        return (
            <>
                <Col xs="12">
                    <div className="top-label">
                        <Form.Check className="form-check float-end mt-1" type="checkbox" checked={this.state.current} onChange={this.onCheckChange} />
                        <Form.Label>{ this.state.caption }</Form.Label>
                    </div>
                </Col>
            </>
        );
    }
}


export default DateRange;