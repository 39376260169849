import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Row, Col, Form } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';

import ConditionalButton from "../conditional-button";
import { isGuid } from "../../utils/string";
import { deepEqual } from "../../utils/object";
import { addMinutes } from "../../utils/date";

import DatePickerEx from "../date-picker";
import ActivitySelect from "../select/activity";
import EmployeeSelect from "../select/employee-list";

class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
    }

    get propsToState() {
        let __working = {
            ...this.props
        }

        if (this.props.activities && isGuid(this.props.activityUuid) && !this.props.selected?.activity) {
            let __activity = this.props.activities.find(obj => {
                return obj.uuid === this.props.activityUuid;
            });

            
            __working.selected = {
                ...__working.selected,
                activity: __activity,
                uuids: {
                    ...__working.selected?.uuids,
                    activity: this.props.activityUuid                    
                }
            }
        }
        const __now = Date.now();

        return {
            client: __working.Client,
            show: __working.show,
            activities: __working.activities || [],
            selected: {
                activity: __working.selected?.activity,
                uuids: {
                    activity: __working.activityUuid,
                    assignedTo: __working.assignedToUuid,
                    event: __working.uuid
                },
            },
            location: __working.location || "",
            caption: __working.caption || "",
            startDate: __working.startDate || __now,
            endDate: __working.endDate || __now,
            send: __working.send || 1,
            shares: __working.shares || [],
            shareable: __working.shareable || [],
            display: {
                person: false || __working.display?.person,
                assignment: true && ((__working.display?.assignment === undefined) || (__working.display?.assignment))
            }
        }
        
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    componentDidUpdate = (old) => {
        if (
            !deepEqual(old?.activities, this.props.activities) ||
            !deepEqual(old?.employees, this.props.employees) ||
            !deepEqual(old?.shareable, this.props.shareable) ||
            !deepEqual(old?.shares, this.props.shares)
        ) {
            this.setState(this.propsToState);
        } else if (this.props.show && !old.show) {
            this.setState(this.propsToState)
        }
    }



    get shareable() {
        const __result = [];
        const __shares = this.shared;
        const __shareable = this.state?.shareable;

        for (let i = 0; i < __shareable?.length || 0; i++) {
            const item = __shareable[i];
            const __share = __shares.find(option => option.id === item.uuid);

            if (!__share) {
                __result.push({
                    id: item.uuid,
                    name: `${item.firstName} ${item.lastName}`
                });
            }
        }

        return __result;
    }

    get shared() {
        const __result = [];
        for (let i = 0; i < this.state?.shares?.length || 0; i++) {
            const item = this.state?.shares[i];
            __result.push({
                id: item.personUuid,
                name: `${item.Person?.firstName} ${item.Person?.lastName}`
            });
        }
        return __result;
    }

    onShareDelete = (idx) => {
        if (idx < 0) return;

        const __uuid = this.shared[idx].id;
        const __shares = this.state?.shares;
        const __idx = __shares.findIndex(option => option.personUuid === __uuid);

        if (__idx > -1) {
            __shares.splice(__idx, 1);
            this.setState({
                ...this.state,
                shares: [...__shares]
            });
        }

    }

    onShareAdd = (tag) => {
        const __shares = this.state?.shares;
        const __item = this.state.shareable?.find(option => option.uuid === tag.id);
        if (__item) {
            __shares.push({ personUuid: __item.uuid, Person: { firstName: __item.firstName, lastName: __item.lastName }, ...__item });
            this.setState({
                ...this.state,
                shares: [...__shares]
            });
        }
    }

    onActivityChange = (e) => {
        this.setState({
            ...this.state,
            selected: {
                ...this.state.selected,
                activity: e,
                uuids: {
                    ...this.state.selected.uuids,
                    activity: e?.uuid
                }
            }
        });
    }

    onAssignedToChange = (e) => {
        if (isGuid(e?.value)) {
            this.setState({
                ...this.state,
                selected: {
                    ...this.state.selected,
                    uuids: {
                        ...this.state.selected.uuids,
                        assignedTo: e?.value
                    }
                }
            });
        }
    }

    onStartDateChange = (e) => {
        const __startDate = new Date(e);
        this.setState({
            ...this.state,
            startDate: __startDate,
            endDate: addMinutes(__startDate, (this.state.selected?.activity?.minutesOffset || 0))
        });
    }

    onEndDateChange = (e) => {
        this.onChange({
            target: {
                name: "endDate",
                value: new Date(e)
            }
        });
    }

    onSendChange = (e) => {
        this.setState({
            ...this.state,
            send: this.state.send===1 ? 0 : 1
        });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onCancel = async (e) => {
        this.props.onCancel(e);
    }
    
    onDelete = async (e) => {
        this.props.onDelete({
            path: this.props.path,
            uuid: this.props.uuid,
            activityUuid: this.state.selected.uuids.activity,
            assignedToUuid: this.state.selected.uuids.assignedTo,
            location: this.state.location,
            caption: this.state.caption,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            activity: this.state.selected.activity,
            send: this.state.send
        });
    }

    onOk = (e) => {
        this.props.onOk({
            path: this.props.path,
            uuid: this.state.selected.uuids.event,
            activityUuid: this.state.selected.uuids.activity,
            assignedToUuid: this.state.selected.uuids.assignedTo,
            location: this.state.location,
            caption: this.state.caption,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            activity: this.state.selected.activity,
            send: this.state.send,
            shares: this.state.shares
        });
    }

    get canSave() {
        return (this.state.caption?.length > 1) && isGuid(this.state.selected?.uuids?.activity);
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="lg" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title || `New Event`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3 pb-3">
                        { ( this.state.display.person) ?                            
                            <Col xs="12">
                                <div className="top-label">
                                    <Form.Control readOnly type="text" name="caption" value={`${this.state?.client?.Person?.firstName || ""} ${this.state?.client?.Person?.lastName || ""}`} onChange={this.onChange} />
                                    <Form.Label>CLIENT</Form.Label>
                                </div>
                            </Col>
                        : ``}
                        <Col xs="12">
                            <div className="top-label">
                                <Form.Control type="text" name="caption" value={this.state.caption || ""} onChange={this.onChange} />
                                <Form.Label>TITLE</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <ActivitySelect
                                    id="activityUuid"
                                    activities={this.props.activities}
                                    name="activityUuid"
                                    onChange={this.onActivityChange}
                                    classNamePrefix="react-select"
                                    placeholder="Activity Type"
                                    value={this.state.selected.uuids?.activity}
                                >
                                </ActivitySelect>
                                <Form.Label>ACTIVITY</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <Form.Control type="text" name="location" value={this.state.location || ""} onChange={this.onChange} />
                                <Form.Label>LOCATION</Form.Label>
                            </div>
                        </Col>
                        {(this.state.display.assignment) ?
                            <Col xs="12">
                                <div className="top-label">
                                    <EmployeeSelect
                                        id="assignedToUuid"
                                        value={this.state.selected?.uuids?.assignedTo}
                                        name="assignedToUuid"
                                        onChange={this.onAssignedToChange}
                                        classNamePrefix="react-select"
                                        placeholder="Assigned To"
                                        employees={this.props.employees}
                                    >
                                    </EmployeeSelect>
                                    <Form.Label>ASSIGNED TO</Form.Label>
                                </div>
                            </Col>
                            : ``}
                        
                        <Col xs="12" md="6">
                            <div className="top-label">
                                <DatePickerEx
                                    id="startDate"
                                    selected={this.state.startDate}
                                    name="startDate"
                                    onChange={this.onStartDateChange}
                                    className="text-end form-control"
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    timeInputLabel="Time:"
                                    showTimeInput
                                />
                                <Form.Label>STARTING</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="top-label">
                                <DatePickerEx
                                    id="endDate"
                                    selected={this.state.endDate}
                                    name="date"
                                    onChange={this.onEndDateChange}
                                    className="text-end form-control"
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    timeInputLabel="Time:"
                                    showTimeInput
                                />
                                <Form.Label>ENDING</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="3">
                            <div className="top-label">
                                <Form.Check className="form-check float-end mt-1" type="checkbox" checked={this.state.send} onChange={this.onSendChange} />
                                <Form.Label>SEND INVITE</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <ReactTags tags={ this.shared } suggestions={ this.shareable } onDelete={this.onShareDelete} onAddition={this.onShareAdd} placeholderText="" minQueryLength={1} />
                                <Form.Label>SHARE WITH</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-danger" display enabled onEnabledClick={this.onDelete} icon="bin">delete</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled={this.canSave} onEnabledClick={this.onOk} icon="save">save</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

ExModal.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps)(ExModal);