import React from "react";
import { Modal, Row, Col, Form } from 'react-bootstrap';
import { deepSet } from "../../../utils/object";

import DatePickerEx from "../../date-picker";

class DateRange extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
    }

    get propsToState() {
        
        /*
        const sample = {
            fields: [
                {
                    type: "date-range",
                    default: [
                        "new Date()",
                        "new Date(Date.now() + (1000 * 60 * 60 * 24))"
                    ],
                    storeTo: [
                        "startDate",
                        "endDate"
                    ],
                    caption: [
                        "from",
                        "to"
                    ],
                    showTime: false,
                }
            ]
        }
        */

        let __working = {
            ...this.props
        }

        const __now = Date.now();
        const __showTime = Boolean(__working.showTime);
        const __format = __showTime ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy";

        const __result = {
            start: {
                caption: __working.caption?.length > 0 ? __working.caption[0] || "STARTING" : "STARTING",
                default: __working.default?.length > 0 ? eval(__working.default[0]) || __now : __now,
                storeTo: __working.storeTo?.length > 0 ? __working.storeTo[0] || "start" : "start",
                value: __working.default?.length > 0 ? eval(__working.default[0]) || __now : __now,
                format: __working.format?.length > 0 ? eval(__working.format[0]) || __format : __format 
            },
            end: {
                default: __working.default?.length > 1 ? eval(__working.default[1]) || __now : __now,
                storeTo: __working.storeTo?.length > 1 ? __working.storeTo[1] || "end" : "end",
                caption: __working.caption?.length > 1 ? __working.caption[1] || "ENDING" : "ENDING",
                value: __working.default?.length > 1 ? eval(__working.default[1]) || __now : __now,
                format: __working.format?.length > 1 ? eval(__working.format[1]) || __format : __format
            },
            showTime: __showTime
        }

        if (this.props.onChange) {
            this.props.onChange({ storeTo: __result.start.storeTo, value: __result.start.value.getTime() });
            this.props.onChange({ storeTo: __result.end.storeTo, value: __result.end.value.getTime() });
        }

        return __result;
        
    }


    componentDidUpdate = (old) => {
        if (this.props && !old) {
            this.setState(this.propsToState)
        }
    }

    onStartDateChange = (e) => {
        const __startDate = new Date(e);
        if (this.props.onChange) this.props.onChange({ storeTo: this.state.start.storeTo, value: __startDate.getTime() });

        this.setState({
            ...this.state,
            start: {
                ...this.state.start,
                value: __startDate.getTime()
            }
        });
    }

    onEndDateChange = (e) => {
        const __endDate = new Date(e);
        if (this.props.onChange) this.props.onChange({ storeTo: this.state.end.storeTo, value: __endDate.getTime() });

        this.setState({
            ...this.state,
            end: {
                ...this.state.end,
                value: __endDate.getTime()
            }
        });
    }


    render() {
        return (
            <>
                <Col xs="12" md="6">
                    <div className="top-label">
                        <DatePickerEx
                            id="startDate"
                            selected={this.state.start.value}
                            name="startDate"
                            onChange={this.onStartDateChange}
                            className="text-end form-control"
                            dateFormat={this.state.start.format}
                            timeInputLabel="Time:"
                            showTimeInput={this.state.showTime}
                        />
                        <Form.Label>{this.state.start.caption}</Form.Label>
                    </div>
                </Col>
                <Col xs="12" md="6">
                    <div className="top-label">
                        <DatePickerEx
                            id="endDate"
                            selected={this.state.end.value}
                            name="endDate"
                            onChange={this.onEndDateChange}
                            className="text-end form-control"
                            dateFormat={this.state.end.format}
                            timeInputLabel="Time:"
                            showTimeInput={this.state.showTime}
                        />
                        <Form.Label>{this.state.end.caption}</Form.Label>
                    </div>
                </Col>
            </>
        );
    }
}


export default DateRange;