import React from "react";
import { Row, Col, Form } from 'react-bootstrap';
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { currency } from "../../utils/number";

class detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.propsToState();
    }

    getTier = (settings, selectedQty, defaultTier) => {
        let __current = defaultTier;
        const __now = new Date();

        if (settings.tiers) {
            
            for (var i = 0; i < settings.tiers.length; i++) {
                const tier = settings.tiers[i];

                let ___active = true;

                if (tier.begins !== undefined) {
                    tier.begins = new Date(tier.begins);
                }

                if (tier.ends !== undefined) {
                    tier.ends = new Date(tier.ends);
                }

                ___active &= (tier.begins === undefined || (__now > tier.begins));
                ___active &= (tier.ends === undefined || (__now < tier.ends));
                ___active &= (tier.low === undefined || (selectedQty >= tier.low));
                ___active &= (tier.high === undefined || (selectedQty < tier.high));
                
                if (___active) {
                    __current = {
                        ...__current,
                        ...tier
                    };
                    break;
                }                
            }
        }
        return __current;
    }

    propsToState = () => {
        const __settings = JSON.parse(this.props.data);
        const __now = new Date();
        const __midnight = new Date(__now);
        __midnight.setHours(0, 0, 0, 0);
        const __tomorrow = new Date(__midnight);
        __tomorrow.setDate(__tomorrow.getDate() + 1);

        let __current = {
            high: 999999,
            low: 0,
            begins: __midnight,
            ends: __tomorrow,
            each: __settings?.defaults?.each || 0
        };

        const qty = __settings?.defaults?.qty || 0;
        __current = this.getTier(__settings, qty, __current);

        const __locks = __settings.lock?.split("|");
        const result = {
            value: this.props.value,
            description: this.props.caption,
            caption: this.props.caption,
            data: this.props.data,
            settings: { ...__settings },
            qty,
            each: __current.each,
            locks: {
                qty: __locks?.indexOf("quantity") >= 0,
                each: __locks?.indexOf("each") >= 0,
                ext: __locks?.indexOf("total") >= 0,
                item: __locks?.indexOf("description") >= 0
            },
            pricing: {
                ...__current
            },
            ext: qty * __current.each,
        };

        if (this.props.onChange) {
            this.props.onChange({
                ...result
            })
        }

        return result;
    }

    onQtyChange = (e) => {
        let __current = {
            ...this.state.pricing,
            each: this.state?.each || this.state?.pricing?.each || this.state?.settings?.defaults?.each || 0
        };
        __current = this.getTier(this.state.settings, e.floatValue, __current);

        this.setState({
            ...this.state,
            qty: e.floatValue,
            pricing: {...__current},
            ext: e.floatValue * __current.each
        }, this.sendOnChange)
    }

    onEachChange = (e) => {
        this.setState({
            ...this.state,
            each: e.floatValue,
            ext: this.state.qty * e.floatValue
        }, this.sendOnChange)
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.sendOnChange)
    }


    sendOnChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange({
                ...this.state
            })
        }
    }

    render() {

        return (
            <Row className="g-3 pb-3">
                <Col xs="12" md="2">
                    <div className="top-label">
                        <NumberFormat
                            displayType="input"
                            type="text"
                            id="qty"
                            name="qty"
                            className="text-end form-control mb-2"
                            thousandSeparator={true}
                            allowNegative={false}
                            value={this.state.qty || 0}
                            onValueChange={this.onQtyChange}
                            readOnly={this.state.locks?.qty} 
                            removeFormatting
                        />
                        <Form.Label>QTY</Form.Label>
                    </div>
                </Col>
                <Col xs="12" md="5">
                    <div className="top-label">
                        <Form.Control type="text" name="caption" value={this.state.caption || ""} onChange={this.onChange} readOnly={this.state.locks?.item} />
                        <Form.Label>ITEM</Form.Label>
                    </div>
                </Col>
                <Col xs="12" md="2">
                    <div className="top-label">
                        <NumberFormat
                            displayType="input"
                            type="text"
                            id="each"
                            name="each"
                            prefix={"$"}
                            className="text-end form-control mb-2"
                            thousandSeparator={true}
                            allowNegative={false}
                            value={this.state.each || 0}
                            onValueChange={this.onEachChange}
                            readOnly={this.state.locks?.each} 
                            removeFormatting
                        />
                        <Form.Label>EACH</Form.Label>
                    </div>
                </Col>
                <Col xs="12" md="3">
                    <div className="top-label mt-3 text-end">
                        {currency(this.state.ext)}
                    </div>
                </Col>
            </Row>
        );
    }
}

detail.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps)(detail);