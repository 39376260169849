import React from "react";
import { Modal, Row } from 'react-bootstrap';

import ConditionalButton from "../conditional-button";
import { deepEqual, deepSet } from "../../utils/object";

import DateRange from "./date-range";
import Boolean from "./boolean";

class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.result = {};

        if (this.props?.fields && Array.isArray(this.props.fields)) {

            for (let i = 0; i < this.props.fields.length; i++) {
                const __field = this.props.fields[i];
                if (Array.isArray(__field.storeTo)) {
                    for (let j = 0; j < __field.storeTo.length; j++) {
                        deepSet(this.result, __field.storeTo[j], eval(__field.default[j]));
                    }
                } else {
                    deepSet(this.result, __field.storeTo, eval(__field.default));
                }
            }
        }
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    onChange = ({ storeTo, value }) => {
        deepSet(this.result, storeTo, value);
    }

    onCancel = async (e) => {
        this.props.onCancel(e);
    }
    
    onOk = (e) => {
        this.props.onOk(this.result);
    }


    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="lg" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title || `Configuration`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3 pb-3">
                        {this.props.fields.map((item, index) => (
                            item.type === "date-range" ?
                                <DateRange {...item} key={`fld_i${index}`} onChange={this.onChange} />
                                : item.type === "boolean" ?
                                    <Boolean {...item} key={`fld_i${index}`} onChange={this.onChange} />
                                    : <></>
                        ))}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onOk} icon="save">ok</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}


export default ExModal;