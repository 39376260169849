import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../../layout/table";
import ConditionalButton from "../../../layout/conditional-button";

import { format as formatDate } from "../../../utils/date";
import { deepEqual } from "../../../utils/object";
import { list as getCodes } from "../../../actions/code";


class Matters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "description",
            index: 0,
            direction: "DESC",
            data: props.items,
            pages: 0,
            sources: [],
        };
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old?.items, this.props?.items)) {
            this.setState({
                ...this.state,
                data: this.props.items
            });
        }
    }

    get headers() {
        return [
            {
                header: "Matter",
                sortable: true,
                source: "description",
                className: "text-muted text-small text-uppercase w-40",
                onRender: this.onLinkFormat
            },
            {
                header: "Attorney",
                source: "attorneyUuid",
                className: "text-muted text-small text-uppercase w-40",
                onFormat: this.onAttorneyFormat
            },
            {
                header: "Date(s)",
                sortable: true,
                source: "representationStarted",
                className: "text-muted text-small text-uppercase w-20",
                onFormat: this.onDatesFormat
            }
        ]
    }

    onItemClick = (e) => {
        if (this.props.onSelect) this.props.onSelect(e.target.attributes["uuid"].value);
    }

    onLinkFormat = (e, row) => {
        return <a uuid={row.uuid.toString()} onClick={this.onItemClick} href="#" role="link">{e}</a>
    }

    onAttorneyFormat = (e, row) => {
        return `${row?.Person?.lastName}, ${row?.Person?.firstName} (${row?.Employee?.title}) `;
    }

    onDatesFormat = (e, row) => {
        const _rep_end = row["representationEnded"];
        return formatDate(e) + (_rep_end ? " to " + formatDate(_rep_end) : "");
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);

    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }

    render() {
        return (
            <>
                <Col xs="12">
                    <Row>
                        <Col xs="6">
                            <h2 className="small-title">Matters</h2>
                        </Col>
                        {this.props.onCreate ? (
                            <Col xs="6" style={{ textAlign: "right" }}>
                                <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.props.onCreate} icon="plus">Create Matter</ConditionalButton>
                            </Col>
                        ) : ``}
                    </Row>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                    />
                </Col>
            </>
        )
    }
}

Matters.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    items: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { getCodes })(Matters);