import api from "../utils/api";

import { send as sendAlert } from "./alert";
import { isGuid } from "../utils/string";
import { GET_REPORTS, GET_REPORT, LOGOUT } from "./types";


export const table = (page, size, order, direction, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/reports/l/p/${page}/s/${size}/o/${order}/${direction}/${search}`);

        dispatch({
            type: GET_REPORTS,
            payload: res.data,
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const list = (configuration) => async (dispatch) => {
    try {
        const { category, clientUuid, matterUuid } = configuration; 
        let extension = isGuid(matterUuid) ? `/t/m/u/${matterUuid}` : isGuid(clientUuid) ? `/t/c/u/${clientUuid}` : category ? `/c/${category}` : "";
        const res = await api.get(`/reports${extension}`);

        if (res?.data) {
            dispatch({
                type: GET_REPORTS,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const get = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/reports/i/${uuid}`);

        if (res?.data) {
            dispatch({
                type: GET_REPORT,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const docx = (xml, templateUuid) => async (dispatch) => {
    try {
        const res = await api.post(`/renderer/transform/docx`, { xml, templateUuid }, { responseType: 'blob' });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const update = (data) => async (dispatch) => {
    try {
        const res = await api.post(`/reports`, data);

        if (res?.data?.success) {
            dispatch(sendAlert("Report updated.", "success"));
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};


export const fields = (pdf) => async (dispatch) => {
    try {
        const res = await api.post("/renderer/prepared/fields", { pdf });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const pdf = (xml, templateUuid) => async (dispatch) => {
    try {
        const res = await api.post(`/renderer/prepared/fill`, { xml, templateUuid }, { responseType: 'blob' });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const xsl = (xml, reportUuid) => async (dispatch) => {
    try {
        const res = await api.post(`/renderer/transform/pdf`, { xml, reportUuid });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const named = (mask, xml) => async (dispatch) => {
    try {
        var xsl =
           `<xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">`+
           `<xsl:output method="text"/>`+
            `<xsl:template match="/data">${mask}</xsl:template>`+
            `</xsl:stylesheet>`;
        const res = await api.post("/renderer/transform", { xml, xsl });
        return res.data.result;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}
